// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import moment from 'moment'

import {
  Grid, Header, Loader, Divider,
  Table, TableRow, TableCell, TableHeader, TableHeaderCell, Icon
} from 'semantic-ui-react'

import { observer } from 'mobx-react'

import { store } from 'services/state'

import './Dashboard.css'
import RevealButton from '../components/RevealButton'
import CopyPopup from '../components/CopyPopup'
import CreateApiKeyButton from '../components/CreateApiKeyButton'
import RevokeApiKeyButton from '../components/RevokeApiKeyButton'

function formatDate (date) {
  return moment(Date.parse(date)).format('MMM DD, YYYY, hh:mm A')
}

export default observer(() => {
  return (
    <><Grid container className="api-card">
      <Grid.Row>
        <Grid.Column>
          <Header size='large'>API Keys
            <CreateApiKeyButton/>
          </Header>
          {store.apiKeys.length > 0
            ? (
              <Table className="api-keys-table" fixed basic='very'>
                <TableHeader>
                  <TableHeaderCell width={5}>API Key Name</TableHeaderCell>
                  <TableHeaderCell width={16}>API Key</TableHeaderCell>
                  <TableHeaderCell width={4}>Status</TableHeaderCell>
                  <TableHeaderCell width={7}>Created</TableHeaderCell>
                  <TableHeaderCell width={7}>Last Modified</TableHeaderCell>
                  <TableHeaderCell width={5}>Actions</TableHeaderCell>
                </TableHeader>
                {store.apiKeys.map((val) => {
                  return (
                    <>
                      <TableRow key={val.id} className='table-row'>
                        <TableCell>
                          {val.description}
                        </TableCell>
                        <TableCell>
                          {val.enabled
                            ? <>
                              <RevealButton label='Show Secret' secret={val.value}/>
                              <CopyPopup text={val.value}/>
                            </>
                            : <>
                              <Icon name='minus'/>
                            </>}
                        </TableCell>
                        <TableCell>{val.enabled
                          ? <>
                            <Icon name='check'/>
                            Active
                          </>
                          : <>
                            <Icon name='minus'/>
                            Revoked
                          </>}</TableCell>
                        <TableCell>{formatDate(val.createdDate)}</TableCell>
                        <TableCell>{!val.enabled
                          ? formatDate(val.lastUpdatedDate)
                          : (<Icon name='minus' className='no-padding'/>)}</TableCell>
                        <TableCell>{val.enabled
                          ? <>
                            <RevokeApiKeyButton apiKeyId={val.id}/>
                          </> : null}
                        </TableCell>
                        <Divider/>
                      </TableRow>
                    </>
                  )
                })}
              </Table>
            )
            // Note: this should be the same size as the text
            : <>
              {store.apiKeyFetchFailed ? <>
                Please wait a minute and try refreshing. If this doesn't work, please contact
                the admin for assistance.
              </> : <>
                {store.apiKeyFetchFinished ? <>
                  There are currently no API keys. In order to use the API, an API key must be created.
                  To proceed, please click the "Create New API Key" button.
                </> : <Loader active inline size='tiny'/>}
              </>}
            </>}
        </Grid.Column>
      </Grid.Row>
    </Grid></>
  )
})
