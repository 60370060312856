// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// semantic-ui
import { Button, Header, Container, Popup } from 'semantic-ui-react'

// markdown for external docs description
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

// services
import { getSubscribedUsagePlan, subscribe, unsubscribe } from 'services/api-catalog'
import { isAuthenticated } from 'services/self'

import { GetSdkButton } from 'components/GetSdk'

// state
import { Observer } from 'mobx-react'
import { store } from 'services/state.js'
// Components
import './SwaggerUILayout.css'
// This is a function for open and close the acordion
function OpenClose () {
  var insideElement = document.getElementById('topSeq')
  var arrowButton = document.getElementById('arrowButton')
  const arrowsvg = '<svg class="arrow" width="20" height="20" aria-hidden="true" focusable="false">'
  const descriptionText = '<span class="descriptionText">Description</span>'
  arrowButton.classList.toggle('button-active')
  if (arrowButton.classList.contains('button-active')) {
    insideElement.style.maxHeight = insideElement.scrollHeight + 'px'
    arrowButton.innerHTML = descriptionText + arrowsvg + '<use href="#large-arrow-up"></use></svg>'
  } else {
    insideElement.style.maxHeight = '0'
    arrowButton.innerHTML = descriptionText + arrowsvg + '<use href="#large-arrow-down"></use></svg>'
  }
}
// Create the plugin that provides our layout component
export const SwaggerLayoutPlugin = () => ({ components: { InfoContainer: InfoReplacement } })

// Note: this is called not as a component, but as a function within a class component. Do
// *not* make this a component, and do *not* use hooks or anything similar in it.
function InfoReplacement ({ specSelectors }) {
  const info = specSelectors.info()
  const version = specSelectors.version()
  const externalDocs = specSelectors.externalDocs()
  const apiTitle = info.get('title')
  const apiDescription = info.get('description')
  const docsDescription = externalDocs.get('description')
  const docsUrl = externalDocs.get('url')

  if (store.api == null) {
    return null
  }
  return (
    <Container fluid textAlign='left' className='fixfloat' style={{ padding: '0px 0px', marginBottom: '14px' }}>
      <div style={{ display: 'flex' }}>
        <div className="topContainer">
          <Header as='h1' id="label">{apiTitle}</Header>
          <SubscriptionButtons/>
          <Button id="arrowButton"
            className="button-active"
            onClick={() => OpenClose()}>
            <span className="descriptionText">Description</span>
            <svg className="arrow" width="20" height="20" aria-hidden="true" focusable="false">
              <use href="#large-arrow-up"></use>
            </svg>
          </Button>
          <div className="topAcordion" id="topSeq">
            <div style={{ display: 'flex', paddingBottom: '1em' }}>
              <div style={{ marginRight: '20px' }}>
                {store.api.apiStage == null ? <p style={{ fontWeight: 'bold' }}>Version</p> : null}
                {/* <p style={{ fontWeight: "bold" }}>Usage Plan</p> */}
              </div>
              <div>
                {store.api.apiStage == null ? <p>{version}</p> : null}
                {/* <p>{store.api.usagePlan.name}</p> */}
              </div>
            </div>
            <div>
              {apiDescription ? <Markdown children={apiDescription} rehypePlugins={[rehypeRaw, remarkGfm]}/> : null}
              {/* <p>{store.api.usagePlan.name}</p> */}
            </div>
            {externalDocs ? (
              <div style={{ paddingBottom: '1em' }}>
                {docsDescription ? <Markdown children={docsDescription} rehypePlugins={[rehypeRaw, remarkGfm]}/> : null}
                <a href={docsUrl}>{docsUrl}</a>
              </div>
            ) : null}
            {store.api.sdkGeneration && <GetSdkButton />}
          </div>
        </div>
      </div>
    </Container>
  )
}

const SubscriptionButtons = (class SubscriptionButtons extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.onButtonClick = this.onButtonClick.bind(this)
  }

  onButtonClick () {
    const { api } = store
    const isApiSubscribed = !!getSubscribedUsagePlan(api.usagePlan.id)
    this.setState({ loading: true })

    if (isApiSubscribed) {
      unsubscribe(api.usagePlan.id)
        .finally(() =>
          this.setState({ loading: false })
        )
    } else {
      subscribe(api.usagePlan.id)
        .finally(() =>
          this.setState({ loading: false })
        )
    }
  }

  render () {
    return <Observer>
      {() => {
        const { api } = store

        if (!api || !isAuthenticated()) {
          return null
        }

        const apiIsSubscribable = !!(api && api.apiStage && api.usagePlan)

        if (apiIsSubscribable) {
          const isApiSubscribed = !!getSubscribedUsagePlan(api.usagePlan.id)
          return (
              isApiSubscribed ? (
                  <Button className="btn-unSubscribe" id="subscribeButton"
                          loading={ this.state.loading }
                          disabled={ this.state.loading }
                          onClick={this.onButtonClick}
                  >
                    Unsubscribe
                  </Button>
              ) : (
                  <Popup disabled={store.anyKeyIsActive()} className='rounded-popup' wide trigger={
                    <div className='subscribe-button-wrapper'>
                      <Button className="btn-Subscribe" id="subscribeButton"
                              loading={ this.state.loading }
                              disabled={!store.anyKeyIsActive() || this.state.loading}
                              onClick={this.onButtonClick}
                      >
                        Subscribe
                      </Button>
                      <span className='warning-message'>
                  Upon clicking the "Subscribe" button, please allow approximately 1 minute for your subscription to be reflected in the system.
                </span>
                    </div>
                  }>You must have an active API key to subscribe. Go to Api Keys page to manage your keys.</Popup>
              )
          )
        } else {
          return <Header style={{ marginTop: '0em' }} as='h4' color='grey'>This version of the API is not configured to be subscribable from the portal. Please contact an admin for more details.</Header>
        }
      }}
    </Observer>
  }
})

export const DisableTryItOutWhenNotSubscribedPlugin = function (system) {
  return {
    wrapComponents: {
      TryItOutButton: (Original) => (props) => {
        return <Observer>
          {() => {
            const { api } = store
            const apiIsSubscribable = !!(api && api.apiStage && api.usagePlan)

            if (!apiIsSubscribable) {
              return <Original {...props} />
            }

            return api.subscribed || props.enabled !== false ? (
              <Original {...props} />
            ) : (
              <button className="btn try-out__btn" disabled={true}>
                Subscribe to the API to try it out
              </button>
            )
          }}
        </Observer>
      },
      execute: (Original) => (props) => {
        return <Observer>
          {() => {
            const { api } = store
            const apiIsSubscribable = !!(api && api.apiStage && api.usagePlan)

            if (!apiIsSubscribable) {
              return <Original {...props} />
            }

            return api.subscribed ? (
              <Original {...props} />
            ) : (
              <button className="btn execute opblock-control__btn" disabled={true}>
                Subscribe to the API before making a request
              </button>
            )
          }}
        </Observer>
      }
    }
  }
}

export const HideApiKeyInCurlPlugin = function (system) {
  return {
    wrapComponents: {
      curl: (Original, system) => class HideApiKeyInCurlComponent extends React.Component {
        render () {
          return <Original {...this.props} />
        }

        findAndHideApiKey () {
          const spansWithApiKey = document.evaluate(
            '//code/span[contains(translate(text(), \'XAPIKEY\', \'xapikey\'), \'x-api-key:\')]',
            document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null)
          for (let i = 0; i < spansWithApiKey.snapshotLength; i++) {
            spansWithApiKey.snapshotItem(i).innerText = '\'x-api-key: ****\''
          }
        }

        componentDidMount () {
          this.findAndHideApiKey()
        }

        componentDidUpdate () {
          this.findAndHideApiKey()
        }
      }
    }
  }
}

export const DisableScrollOnAuthorizationWindowPlugin = function (system) {
  return {
    wrapComponents: {
      authorizeBtn: (Original, system) => class HideApiKeyInCurlComponent extends React.Component {
        render () {
          return <Original {...this.props} />
        }

        componentDidUpdate () {
          const backdrop = document.evaluate(
            '//div[contains(@class, \'backdrop-ux\')]',
            document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null)
          if (backdrop.snapshotLength !== 0) {
            document.body.style.overflow = 'hidden'
          } else {
            document.body.style.overflow = null
          }
        }
      }
    }
  }
}

export const EnforceTextCsvContentDispositionDownloadPlugin = function (system) {
  return {
    wrapComponents: {
      responseBody: (ResponseBody) => (props) => {
        const { content, headers = {} } = props
        if ((headers['Content-Disposition'] || headers['content-disposition']) && headers['content-type'] === 'text/csv') {
          return <ResponseBody {...props} content={new Blob([content], { type: 'application/octet-stream' })} contentType={'application/octet-stream'} />
        }
        return <ResponseBody {...props} />
      }
    }
  }
}

export default SwaggerLayoutPlugin
