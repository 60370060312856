import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import CopyToClipboard from 'react-copy-to-clipboard'
import './CopyPopup.css'

const TIMEOUT_DURATION = 2500

class CopyPopup extends React.Component {
  state = { isOpen: false }

  handleOpen = () => {
    clearTimeout(this.timeout)
    this.setState({ isOpen: true })

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false })
    }, TIMEOUT_DURATION)
  }

  render () {
    return (
      <Popup content='Copied!' on='click' position='right center' className='rounded-popup'
        open={this.state.isOpen} trigger={
          <div className='copy-to-clipboard-wrapper'>
            <CopyToClipboard text={this.props.text} onCopy={this.handleOpen}>
              <Icon name='clone outline' link size='large'/>
            </CopyToClipboard>
          </div>
        }/>
    )
  }
}

export default CopyPopup
