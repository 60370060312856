// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { observable, reaction } from 'mobx'
import _ from 'lodash'

import { getSubscribedUsagePlan } from 'services/api-catalog'

const MAX_ACTIVE_API_KEYS = 4

/**
 * A function that returns a new object contain the default store
 */
function storeDefaults () {
  return {
    api: undefined,
    apiKeys: [],
    apiKeyFetchFailed: false,
    apiKeyFetchFinished: false,

    apiList: {
      loaded: false,
      apiGateway: [],
      generic: []
    },

    user: undefined,
    idToken: undefined,
    refreshToken: undefined,
    accessToken: undefined,

    usagePlans: [],

    subscriptions: [],

    notifications: [],

    visibility: {
      apiGateway: [],
      generic: {}
    }
  }
}

/**
 * Trick for logging the store: clone the object. i.e. _.cloneDeep(store) or JSON.parse(JSON.stringify(store))
 */
export const store = observable({
  ...(storeDefaults()),

  initialize () {
    Object.assign(this, storeDefaults())

    return this
  },

  maxKeysNotReached () {
    return this.apiKeys.filter(value => value.enabled).length < MAX_ACTIVE_API_KEYS
  },

  allowedToCreateKey () {
    return this.maxKeysNotReached() &&
      (this.apiKeyFetchFinished === true || this.apiKeys.length > 0)
  },

  isLastActiveApiKey () {
    return this.apiKeys.filter(value => value.enabled).length === 1
  },

  anyKeyIsActive () {
    return this.apiKeys.filter(value => value.enabled).length > 0
  },

  getAnyActiveKey () {
    return this.apiKeys.filter(value => value.enabled)[0]
  },

  /**
   * Reset the entire store to the original values
   */
  clear () {
    return this.initialize()
  },

  /**
   * Reset specific keys on the store to their initial values
   *
   * @param {string[]} keys   The keys that should be reset. Accepts lodash paths. (e.g. )
   */
  reset (...keys) {
    const defaults = storeDefaults()
    keys.forEach(key => _.set(this, key, _.get(defaults, key)))
    return this
  },

  resetUserData () {
    this.reset('apiKeys', 'apiList', 'user', 'subscriptions', 'idToken', 'accessToken', 'refreshToken',
        'usagePlans', 'notifications', 'visibility')
  }
})

/**
 *
 * A short-hand function for creating reactions with protections against cyclical errors.
 *
 * @param {Function} triggerFn   A function that determines when fire the effectFn and what to pass to the effectFn.
 * @param {Function} effectFn   The side-effect to run when the data tracked by the triggerFn is changed.
 *
 * A note on MobX reactions: the side effect will "only react to data that was accessed in the data expression" and will only fire "when the data returned by the expression has changed". (https://mobx.js.org/refguide/reaction.html)
 */
function reactTo (triggerFn, effectFn) {
  // note -- had issues with cyclical reactions in the past
  // the comments below will fix them if they come up again
  // DO NOT REMOVE THEM

  // function restartReaction() {
  reaction(
    triggerFn,
    (data, action) => {
      // action.dispose() // clear this "listener" so we don't cycle

      effectFn(data, action)

      // restartReaction() // restart the reaction after doing stuff to the data
    }
  )
  // }

  // restartReaction()
}

reactTo(
  () => ({ subscriptions: store.subscriptions, usagePlans: store.usagePlans }),
  ({ usagePlans }) => {
    updateSubscriptionStatus(usagePlans)
  }
)

/**
 * A helper function that simple re-builds the subscribed status for each api in the catalog.
 *
 * Should be run every time either the catalog updates or the
 */
function updateSubscriptionStatus (usagePlans) {
  if (usagePlans) {
    usagePlans.forEach(usagePlan => {
      const subscribed = !!getSubscribedUsagePlan(usagePlan.id)
      usagePlan.subscribed = subscribed

      usagePlan.apis.forEach(api => { api.subscribed = subscribed })
    })
  }
}

export default store.initialize()
