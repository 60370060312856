// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu, Image, Modal, Container } from 'semantic-ui-react'
import Banner from './Banner'
import './NavBar.css'

import {
  isAuthenticated,
  logout
} from 'services/self'

import { getCognitoUrl } from '../services/api'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import MenuLink from 'components/MenuLink'
import { store } from 'services/state'
import { Link, withRouter } from 'react-router-dom'

// eslint-disable-next-line react-hooks/rules-of-hooks
// const [count, setCount] = useState(true)

export const NavBar = withRouter(observer(
  class NavBar extends React.Component {
    constructor () {
      super()
      this.firstPathSegmentToId = {
        '': 'dev-portal',
        'getting-started': 'get-started',
        apis: 'apis',
        support: 'support',
        dashboard: 'dropdown1',
        admin: 'dropdown1'
      }
      this.gettingStarted = React.createRef()
      this.state = {
        isActive: false,
        modalOpen: false
      }
      this.handleOpen = () => this.setState({ modalOpen: true })
      this.handleClose = () => this.setState({ modalOpen: false })
    }

    clearPreviousTab () {
      const previousTab = window.sessionStorage.getItem('activeTab')
      if (!previousTab) {
        return
      }
      if (previousTab && document.getElementById(previousTab)) {
        document.getElementById(previousTab).classList.remove('set-tab-active')
      }
      window.sessionStorage.removeItem('activeTab')
    }

    setActiveTab (activeTab) {
      window.sessionStorage.setItem('activeTab', activeTab)
      if (activeTab && document.getElementById(activeTab)) {
        document.getElementById(activeTab).classList.add('set-tab-active')
      }
    }

    toggleActiveNav (event) {
      this.clearPreviousTab()
      this.setActiveTab(event)
    }

    render () {
      // const { isOpen } = this.state
      this.clearPreviousTab()
      this.setActiveTab(this.firstPathSegmentToId[this.props.location.pathname.split('/')[1]])
      const email = store.user && store.user.email
      return (
      <Container
        as={Menu}
        fixed='top'
        className='page-header-container'
      >
      <Menu inverted borderless attached stackable>
        <Image size='mini' src='/custom-content/intel-header-logo.svg'/>
        <MenuLink id="dev-portal" onClick={ () => { this.toggleActiveNav('dev-portal') } } to='/'>{fragments.Home.title}</MenuLink>
        <MenuLink id="get-started" onClick={ () => { this.toggleActiveNav('get-started') } } to='/getting-started'>{fragments.GettingStarted.title}</MenuLink>
        {isAuthenticated() && <MenuLink id="apis" onClick={ () => { this.toggleActiveNav('apis') } } to='/apis'>{fragments.APIs.title}</MenuLink>}
        <MenuLink id="support" onClick={ () => { this.toggleActiveNav('support') } } to='/support'>SUPPORT</MenuLink>
        {/* <MenuLink><i className="intelicon-cancel-filter"></i></MenuLink> */}
        <Menu.Menu position='right'>
          {isAuthenticated() ? <>
            <MenuLink>
              {/* <MenuLink className="user-icon"><i className="fa fa-fw fa-user"></i></MenuLink> */}
              <div className="row row-cols-auto">
                <div className="col">
                  <div className="dropdown">
                    <label>
                      <div>
                        {email && <span id="logged-in-user" className="user-name">
                          {email}
                        </span>}</div>
                    </label>
                    <label id="dropdown1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i id="dropdown-icon" className="fa-intel-user-checked-o"></i>
                    </label>
                    <ul aria-labelledby="dropdown1" className="dropdown-menu">
                      {isAuthenticated() && <li><Link id="api-keys" className="dropdown-item" to='/dashboard'>Api Keys</Link></li>}
                      <li><a className="dropdown-item" href="#" onClick={this.handleOpen}>My Intel Account</a></li>
                      <li><a id="signout" className="dropdown-item" href="#" onClick={logout}>Sign Out</a></li>
                    </ul>
                    <Modal
                      size='small'
                      // open={isOpen}
                      header=''
                      content={
                        'You will be redirected to Intel Account page.'
                      }
                      actions={[
                        'Close',
                        {
                          content: 'Go to My Intel Account',
                          primary: true,
                          onClick: () => {
                            window.open(window.config.redirectIntelPage,
                              '_blank', 'noopener,noreferrer')
                          }
                        }
                      ]}
                      open={this.state.modalOpen}
                      onClose={this.handleClose}
                      style={{ height: '180px', paddingBottom: '1em', width: '534px', overflow: 'hidden', top: '80px' }}
                    >
                    </Modal>
                  </div>
                </div>
              </div>
            </MenuLink>
          </> : <>
            <MenuLink className="user-icon" to={getCognitoUrl('oauth2/authorize')}> <i id="signin" className="fa-intel-user-o"></i>
            </MenuLink>
            {/* <MenuLink to={getCognitoUrl('signup')}>Register</MenuLink> */}
          </>}
        </Menu.Menu>
      </Menu>
        <div className="banner-box"><Banner /></div>
      </Container>
      )
    }
  }
))

export default NavBar
