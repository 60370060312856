// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import './ApisMenu.css'

// semantic-ui
import { Loader, Grid, Icon, Dropdown } from 'semantic-ui-react'

// store
import { observer } from 'mobx-react'
import { store } from 'services/state'

// utilities
import _ from 'lodash'
import Sidebar from 'components/Sidebar/Sidebar'
import MenuLink from 'components/MenuLink'

function getApisWithStages (selectedApiId, selectedStage, activateFirst) {
  const apiList = [].concat(_.get(store, 'apiList.generic', []), _.get(store, 'apiList.apiGateway', [])).map(api => ({
    group: api.apiId || api.id,
    id: api.apiStage || api.id,
    title: api.swagger.info.title,
    route: `/apis/${api.id}` + (api.apiStage ? '/' + api.apiStage : ''),
    active: (
      (selectedApiId && (api.id === selectedApiId || api.apiId === selectedApiId)) &&
      (!selectedStage || api.apiStage === selectedStage)
    ),
    stage: api.apiStage
  }))

  return _.toPairs(_.groupBy(apiList, 'group'))
    .map(([group, apis]) => ({ group, apis, active: _.some(apis, 'active'), title: apis[0].title }))
}

export default observer(function ApisMenu (props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  // If we're still loading, display a spinner.
  // If we're not loading, and we don't have any apis, display a message.
  // If we're not loading, and we have some apis, render the appropriate api subsections for apiGateway and generic apis
  if (!store.apiList.loaded) {
    return <Loader active />
  }

  const apiGroupList = getApisWithStages(
    props.activateFirst && props.path.params.apiId,
    props.path.params.stage,
    props.activateFirst
  )

  if (!apiGroupList.length) {
    return <p id='no-apis-published'>No APIs Published</p>
  }

  if (props.activateFirst && !props.path.params.apiId) {
    return <Redirect to={apiGroupList[0].apis[0].route} />
  }

  return (
        <Grid style={{ margin: 0, width: '100%' }}>
          <Grid.Row only="mobile" className='mobile-grid-row'>
          <Grid.Column className='mobile-grid-column'>
          <Dropdown
            text='Apis'
            icon={isDropdownOpen ? 'angle up' : 'angle down'}
            className='mobile-apis-dropdown'
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
          >
            <Dropdown.Menu>
          {apiGroupList.map(({ apis, title, group, active }) => (
            apis[0].stage ? (
              <>
                {apis.map(({ route, stage, active, id }) => (
                  <>
                    <Dropdown.Item as={MenuLink}
                      key={id}
                      to={route}
                      active={active}
                    >
                      <div className='icon-wrapper'>
                        <Icon name='square' size='tiny' className='list-icon'/>
                        {title}
                      </div>
                    </Dropdown.Item>
                  </>
                ))}
              </>
            ) : null
          ))}
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
          </Grid.Row>
          <Grid.Row only="tablet computer" className='computer-grid-row'>
            <Grid.Column>
              <Sidebar>
                {apiGroupList.map(({ apis, title, group, active }) => (
                  apis[0].stage ? (
                    <>
                      {apis.map(({ route, stage, active, id }) => (
                        <MenuLink
                          key={id}
                          to={route}
                          active={active}
                          className='computer-menu-link'>
                          <div className='icon-wrapper'>
                              <Icon name='square' size='tiny' className='list-icon'/>
                              {title}
                            </div>
                        </MenuLink>
                      ))}
                    </>
                  ) : null
                ))}
              </Sidebar>
            </Grid.Column>
          </Grid.Row>
      </Grid>
  )
})
