import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import './RevokeApiKeyButton.css'
import { revokeApiKey } from '../services/api-catalog'
import { store } from '../services/state'

const RevokeApiKeyButton = ({ apiKeyId }) => {
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      centered={false}
      trigger={<Button negative disabled={loading} loading={loading}>Revoke</Button>}
      header=''
      fitted
      content={store.isLastActiveApiKey()
        ? 'Are you sure you want to revoke the last active API key? ' +
        'Without an active API key, API access will be lost.'
        : 'Are you sure you want to permanently revoke this API key?'
      }
      size='tiny'
      actions={[
        'Cancel',
        {
          key: apiKeyId,
          content: 'Revoke',
          primary: true,
          onClick: () => {
            setLoading(true)
            revokeApiKey(apiKeyId)
              .finally(() => setLoading(false))
          }
        }
      ]}
    />
  )
}

export default RevokeApiKeyButton
