// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'
import { Container } from 'semantic-ui-react'

export default observer(() => (
  <>
    <Container>
      <div id="support-page" className="card-alignment d-flex justify-content-start">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h3>Support</h3>
                <p className="card-text">
                  Please contact your Intel representatives (Field Application Engineers) for general Intel On Demand inquiries.</p>
                <p>For help with technical issues, please use the <a href="https://www.intel.com/content/www/us/en/design/support/ips/training/welcome.html" target="_blank" >Intel Premier Support</a> (IPS) system to create and manage your support requests.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </>
))
