// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

// semantic-ui
import { Container, Header, Icon } from 'semantic-ui-react'

// services
import { updateUsagePlansAndApisList, getApi } from 'services/api-catalog'

// components
import ApisMenu from 'components/ApisMenu'
import {
  SwaggerLayoutPlugin,
  DisableTryItOutWhenNotSubscribedPlugin,
  HideApiKeyInCurlPlugin,
  DisableScrollOnAuthorizationWindowPlugin,
  EnforceTextCsvContentDispositionDownloadPlugin
} from 'components/SwaggerUiLayout'
import PageWithSidebar from 'components/PageWithSidebar'

// state
import { store } from 'services/state.js'
import { Observer } from 'mobx-react'

export default class ApisPage extends React.Component {
  componentDidMount () { this.updateApi(true) }
  componentDidUpdate () { this.updateApi(false) }

  updateApi (isInitial) {
    if (isInitial) {
      updateUsagePlansAndApisList(true)
    }
    return getApi(this.props.match.params.apiId || 'ANY', true, this.props.match.params.stage, isInitial)
  }

  render () {
    return (
      <><PageWithSidebar
        sidebarContent={<ApisMenu path={this.props.match} activateFirst={true} />}
        SidebarPusherProps={{ className: 'swagger-section sidebar-swagger-content' }}
        sidebarContentClass={{ className: 'sidebar-content' }}
      >
        <Swagger />
      </PageWithSidebar></>
    )
  }
}

function Swagger () {
  let errorHeader
  let errorBody

  if (store.apiList.loaded) {
    if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
      errorHeader = 'No APIs Published'
      errorBody = 'Your administrator hasn\'t added any APIs to your account. Please contact them to publish an API.'
    } else if (!store.api) {
      errorHeader = 'No Such API'
      errorBody = 'The selected API doesn\'t exist.'
    }
  }

  const swaggerUiPlugins = [
    SwaggerLayoutPlugin(),
    new DisableTryItOutWhenNotSubscribedPlugin(),
    new HideApiKeyInCurlPlugin(),
    new DisableScrollOnAuthorizationWindowPlugin(),
    new EnforceTextCsvContentDispositionDownloadPlugin()
  ]

  return <Observer>
    {}
    {() => store.api == null ? null : <div className={'card container swagger-ui-container'}><SwaggerUI spec={store.api.swagger} plugins={swaggerUiPlugins} style={{ padding: '0 20px' }} onComplete={(swaggerUi) => complete(swaggerUi)}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              {errorHeader && errorBody && (
                <>
                  <Header as='h2' icon textAlign='center' style={{ padding: '40px 0px' }}>
                    <Icon name='warning sign' circular />
                    <Header.Content>{errorHeader}</Header.Content>
                  </Header>
                  <Container text textAlign='justified'>
                    <p>{errorBody}</p>
                  </Container>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </SwaggerUI></div>}
  </Observer>
}

function complete (swaggerUI) {
  if (store.anyKeyIsActive()) {
    swaggerUI.preauthorizeApiKey('api_key', store.getAnyActiveKey().value)
  }
}
