// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import '../../src/index.css'
// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'
import { Segment, Container } from 'semantic-ui-react'
// semantic-ui
// import { Container } from 'semantic-ui-react'
// import Footerlogo from 'pages/images/logo-black-full.svg'
// import Footer from 'components/Footer'

export default observer(() => (
  <>
    <Segment id="getting-started" vertical>
      <Container fluid text textAlign='justified' className="gettingstarted-container">
        <fragments.GettingStarted.jsx />
      </Container>
    </Segment>
  </>
))
