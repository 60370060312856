// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
// import { Link } from 'react-router-dom'

// semantic-ui
import { Segment, Container } from 'semantic-ui-react'

import wrinches from 'pages/images/upe-cta-drivers-icon.png'
import '../index.css'
// import Footer from 'components/Footer'

// services
import { isAuthenticated } from 'services/self'

import _ from 'lodash'

export const HomePage = observer(() => (
  <>
    <Segment id="home-page" vertical >
      <Container fluid text textAlign='justified'>
        <div className="main-contant">
          <div className="main-left">
            <img className="wrinches" src={wrinches} alt="img" />
          </div>
          <div className="main-right">
            {isAuthenticated() ? <>
              <fragments.Home.jsx />
            </> : <>
              <fragments.HomeUnsigned.jsx />
              { _.get(window, 'config.registrationUriEnabled') === true ? <>
                  <a target="_blank" href={_.get(window, 'config.registrationUri')}>
                  Register now</a> for an Intel<sup>&#174;</sup> On Demand Account
              </> : <></>
              }
            </>}
          </div>
        </div>
      </Container>
    </Segment>
  </>
))

export default HomePage
