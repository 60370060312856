import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import './RevealButton.css'

const RevealButton = ({ label, secret }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      {!show && <Button basic onClick={() => setShow(prev => !prev)}>{label}</Button>}
      {show && <>{secret}</>}
    </>
  )
}

export default RevealButton
