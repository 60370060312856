// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import * as queryString from 'query-string'

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis'
import Support from 'pages/Support'
import Footer from 'components/Footer'
import ThirdPartyLicenses from 'pages/ThirdPartyLicenses'
// components
import AlertPopup from 'components/AlertPopup'
import GlobalModal from 'components/Modal'
import NavBar from 'components/NavBar'
import Feedback from './components/Feedback'
import ApiSearch from './components/ApiSearch'
import { createBrowserHistory } from 'history'
import { isRegistered, init, login, logout } from 'services/self'
import './index.css'
import PageContentWrapper from './components/PageContentWrapper'

// TODO: Feedback should be enabled if
// the following is true && the current
// user is not an administrator
const feedbackEnabled = window.config.feedbackEnabled

export const RegisteredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      isRegistered()
        ? <Component {...props} />
        : <Redirect to='/' />
    )}
  />
)

// To shut up a dev warning
const HomeWrap = props => <Home {...props} />
const GettingStartedWrap = props => <GettingStarted {...props} />
const DashboardWrap = props => <Dashboard {...props} />
const SupportWrap = props => <Support {...props} />
const ThirdpartylicensesWrap = props => <ThirdPartyLicenses {...props} />
const history = createBrowserHistory()

function adobeAnalyticsPageView () {
  setTimeout(() => {
    if (window.wap_tms && window.wap_tms.custom?.trackGaVirtualPageView) { // analytics
      window.wap_tms.custom.trackGaVirtualPageView(window.location.pathname)
    }
  }, 200)
}

class App extends React.Component {
  constructor () {
    super()
    init()
    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    if (window.location.hash && window.location.hash[1] === '!') {
      const hashRoute = window.location.hash.substring(2)
      window.history.pushState({}, 'home page', hashRoute)
    }

    adobeAnalyticsPageView() // first load have to send analytics too

    if (window.location.port.length === 0) { // localhost not send analytics
      history.listen(() => {
        adobeAnalyticsPageView()
      })
    }
  }

  render () {
    return (
      <Router history={history}>
        <>
          <NavBar />
          <GlobalModal />
          <PageContentWrapper>
          <Switch>
            <Route exact path='/' component={HomeWrap} />
            <Route
              exact
              path='/index.html'
              component={() => {
                const { action } = queryString.parse(window.location.search)
                if (action === 'login') {
                  login()
                } else if (action === 'logout') {
                  logout()
                }
                return <Redirect to='/' />
              }}
            />
            <Route path='/getting-started' component={GettingStartedWrap} />
            <Route path='/support' component={SupportWrap} />
            <Route path='/thirdpartylicenses' component={ThirdpartylicensesWrap} />
            <RegisteredRoute path='/dashboard' component={DashboardWrap} />
            <RegisteredRoute exact path='/apis' component={Apis} />
            <RegisteredRoute exact path='/apis/search' component={ApiSearch} />
            <RegisteredRoute exact path='/apis/:apiId' component={Apis} />
            <RegisteredRoute path='/apis/:apiId/:stage' component={Apis} />
            <Route path='/login' render={() => { login(); return <Redirect to='/' /> }} />
            <Route path='/logout' render={() => { logout(); return <Redirect to='/' /> }} />
            <Route component={() => <h2>Page not found</h2>} />
          </Switch>
          </PageContentWrapper>
          {feedbackEnabled && <Feedback />}
          <AlertPopup />
          <Footer />
        </>
      </Router>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
