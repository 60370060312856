import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import './CreateApiKeyButton.css'
import { createApiKeyFun } from '../services/api-catalog'
import { store } from '../services/state'

const CreateApiKeyButton = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Popup content='Reached maximum amount of active API keys' disabled={store.maxKeysNotReached()} className='rounded-popup'
      trigger={
        <div className='create-key-wrapper'>
          <Button className='btn-create' primary
            disabled={!store.allowedToCreateKey() || loading}
            loading={loading}
            content='Create New API Key'
            onClick={() => {
              setLoading(true)
              createApiKeyFun()
                .finally(() => setLoading(false))
            }
            }/>
        </div>
      }/>

  )
}

export default CreateApiKeyButton
