import React from 'react'
import Footerlogo from 'pages/images/logo-black-full.svg'
import { Link } from 'react-router-dom'
import { Container, List, Grid } from 'semantic-ui-react'

function clearPreviousTab () { // similar in navbar todo to optimize
  const previousTab = window.sessionStorage.getItem('activeTab') || 'dev-portal'
  if (previousTab && document.getElementById(previousTab)) {
    document.getElementById(previousTab).classList.remove('set-tab-active')
  }
  window.sessionStorage.removeItem('activeTab')
}

const Footer = props => {
  return (
    <footer className="global blade solid brand-lighter-gray theme-light-2 component" data-component-id="1" data-component="footer" role="contentinfo" data-wap_type="wa_skip_track" id="skip-footer">
      <Container className="ui mobile reversed two column grid stackable justified footer-container">
          <div className="two wide column footer-logo-container">
              <a href="https://www.intel.com/content/www/us/en/homepage.html" className="footer-logo-rebrand" aria-label="Intel Footer Logo" title="Intel Footer Logo">
                  <img src={Footerlogo} width="118" className="footer-logo-rebrand" alt="Intel Footer Logo" title="Intel Footer Logo">
                  </img> </a>
          </div>
          <div className="fourteen wide column footer-links-container">
            <Grid className="footer-links" doubling stackable stretched>
              <Grid.Row>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/company-overview/company-overview.html" className="footer-item-head">Company Information</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/corporate-responsibility/corporate-responsibility.html" className="footer-item-head">Our Commitment</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/diversity/diversity-at-intel.html" className="footer-item-head">Diversity & Inclusion</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/blogs-communities-social.html" className="footer-item-head">Communities </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intc.com/" className="footer-item-head">Investor Relations</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/company-overview/contact-us.html" className="footer-item-head">Contact Us</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/newsroom/home.html" className="footer-item-head">Newsroom</a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/jobs/jobs-at-intel.html" className="footer-item-head"> Jobs</a>
              </Grid.Column>
              </Grid.Row>
            </Grid>

            <List horizontal size="massive" className="footer-social-list">
              <List.Item className="footer-social-list-item">
                <List.Content>
                <a href="https://www.facebook.com/Intel" target="" className="footer-item-head" aria-label="Intel on Facebook" data-wap="{&quot;linktype&quot;:&quot;footersocial&quot;}">
                  <i aria-hidden="true" className="fa-facebook"></i>
                </a>
                </List.Content>
              </List.Item>
              <List.Item className="footer-social-list-item">
                <List.Content>
                <a href="https://twitter.com/intel" target="" className="footer-item-head" aria-label="Intel on Twitter" data-wap="{&quot;linktype&quot;:&quot;footersocial&quot;}">
                  <i aria-hidden="true" className="fa-twitter"></i>
                </a>
                </List.Content>
              </List.Item>
              <List.Item className="footer-social-list-item">
                <List.Content>
                <a href="https://www.linkedin.com/company/intel-corporation" target="" className="footer-item-head" aria-label="Intel on LinkedIn" data-wap="{&quot;linktype&quot;:&quot;footersocial&quot;}">
                  <i aria-hidden="true" className="fa-linkedin"></i>
                </a>
                </List.Content>
              </List.Item>
              <List.Item className="footer-social-list-item">
                <List.Content>
                <a href="https://www.youtube.com/user/channelintel?sub_confirmation=1" target="" className="footer-item-head" aria-label="Intel on YouTube" data-wap="{&quot;linktype&quot;:&quot;footersocial&quot;}">
                  <i aria-hidden="true" className="fa-youtube"></i>
                </a>
                </List.Content>
              </List.Item>
              <List.Item className="footer-social-list-item">
                <List.Content>
                <a href="https://www.instagram.com/intel/" target="" className="footer-item-head" aria-label="Intel on Instagram" data-wap="{&quot;linktype&quot;:&quot;footersocial&quot;}">
                  <i aria-hidden="true" className="fa-instagram"></i>
                </a>
                </List.Content>
              </List.Item>
            </List>

            <Grid columns={8} doubling divided stretched className="footer-links">
              <Grid.Row>
              <Grid.Column className="footer-links-column">
                <span className="footer-no-style-link">
                  <small>© Intel Corporation</small>
                </span>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                <a href="https://www.intel.com/content/www/us/en/legal/terms-of-use.html" className="footer-item-head">
                  <small>Terms of Use</small>
                </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/legal/trademarks.html" className="footer-item-head">
                    <small>*Trademarks</small>
                  </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/privacy/intel-cookie-notice.html" data-cookie-notice="true" className="footer-item-head">
                    <small>Cookies</small>
                  </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html" className="footer-item-head">
                    <small>Privacy</small>
                  </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <Link to={'/thirdpartylicenses'} onClick={() => { clearPreviousTab() } } className="footer-item-head"><small>Third Party Licenses</small></Link>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/corporate-responsibility/statement-combating-modern-slavery.html" className="footer-item-head">
                    <small>Supply Chain Transparency </small>
                  </a>
              </Grid.Column>
              <Grid.Column className="footer-links-column">
                  <a href="https://www.intel.com/content/www/us/en/siteindex.html" className="footer-item-head">
                    <small>Site Map</small>
                  </a>
              </Grid.Column>
              </Grid.Row>
            </Grid>

              <p className='footer-paragraph'>
                  Intel technologies may require enabled hardware, software or service activation. // No product or component can be absolutely secure. // Your costs and results may vary. // Performance varies by use, configuration and other factors.
                        // See our complete legal <u><a href="https://edc.intel.com/content/www/us/en/products/performance/benchmarks/overview/?r=219444055" className="footer-item-head">Notices
                  and Disclaimers</a></u>. // Intel is committed to respecting human rights and avoiding complicity in human rights abuses. See Intel’s <a href="https://www.intel.com/content/www/us/en/policy/policy-human-rights.html" className="footer-item-head"><u>Global
                    Human Rights Principles</u></a>. Intel’s products and software are intended only to be used in applications that do not cause or contribute to a violation of an internationally recognized human right.
              </p>
          </div>
      </Container>
    </footer>
  )
}

export default Footer
