// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Menu } from 'semantic-ui-react'
import './Sidebar.css'

function Sidebar ({ style, ...props }) {
  return (
    <Menu
      inverted vertical borderless attached
      className='sidebar-menu'
      {...props}
    />
  )
}

export default Sidebar
