// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'

// semantic-ui
import {
  Grid
} from 'semantic-ui-react'
import './PageWithSidebar.css'

const headerHeight = 170

const useRefHeight = (ref) => {
  const [height, setHeight] = useState(1)

  useEffect(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect()
      setHeight(boundingRect.height)
    }
  }, [ref])
  return height
}

const PageWithSidebar = ({
  sidebarContent,
  children,
  SidebarPusherProps,
  sidebarContentClass
  }) => {
  const [sidebarMenuHeight, setSidebarMenuHeight] = useState(400)
  const contentRef = useRef()
  const contentHeight = useRefHeight(contentRef)

  const getSidebarHeight = () => {
    const footerElement = document.getElementById('skip-footer')?.getBoundingClientRect()

    const footerHeightInView = window.innerHeight - footerElement.top
    if (footerHeightInView > 0) {
      return Math.floor(window.innerHeight - headerHeight - footerHeightInView)
    }
    return Math.floor(window.innerHeight - headerHeight)
  }

  const checkSidebarHeight = useCallback(() => {
    const calcSidebarHeight = getSidebarHeight()
    if (calcSidebarHeight !== sidebarMenuHeight) {
      setSidebarMenuHeight(calcSidebarHeight)
    }
  }, [sidebarMenuHeight])

  useLayoutEffect(() => {
    checkSidebarHeight()
  }, [contentHeight, checkSidebarHeight])

  useEffect(() => {
    window.addEventListener('scroll', checkSidebarHeight)
    return () =>
      window.removeEventListener('scroll', checkSidebarHeight)
  }, [checkSidebarHeight])

    return (
      <Grid column={2} stackable className="page-with-sidebar">
        <Grid.Column
          only="mobile"
          width={2}
          className="page-with-sidebar-menu-container page-with-sidebar-menu-mobile-container"
        >
          {sidebarContent}
        </Grid.Column>
        <Grid.Column only="tablet computer" width={2} className="page-with-sidebar-menu-container">
          <div style={{ minHeight: sidebarMenuHeight }} className="page-with-sidebar-menu">
              <div className="page-with-sidebar-menu-item">
                <div className="page-with-sidebar-menu-item-content">
                  {sidebarContent}
                </div>
              </div>
          </div>
        </Grid.Column>
        <Grid.Column width={14} className="page-with-sidebar-content">
          <div ref={contentRef}>
            {children}
          </div>
        </Grid.Column>
      </Grid>
    )
}

export default PageWithSidebar
