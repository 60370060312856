// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'

import _ from 'lodash'

import { getLoginRedirectUrl } from 'services/self'

export const awsRegion = _.get(window, 'config.region')
export const cognitoRegion = _.get(window, 'config.region')
export const cognitoUserPoolId = _.get(window, 'config.userPoolId')
export const cognitoIdentityPoolId = _.get(window, 'config.identityPoolId')
export const cognitoClientId = _.get(window, 'config.userPoolClientId')
export const cognitoDomain = _.get(window, 'config.userPoolDomain')
export const identityProvider = _.get(window, 'config.identityProvider')
export const cognitoRefreshToken = 'refreshToken'
export const cognitoAccessToken = 'accessToken'

AWS.config.region = cognitoRegion

let cachedClient
let cachedClientWithCredentials

class DecoratedApiGatewayClient {
  constructor (client, accessToken) {
    this.client = client
    this.accessToken = accessToken
  }

  withAccessToken (additionalParams) {
    if (!additionalParams) {
      additionalParams = {}
    }
    if (!additionalParams.headers) {
      additionalParams.headers = {}
    }
    additionalParams.headers['x-api-key'] = this.accessToken
    return additionalParams
  }

  rootOptions (params, body, additionalParams) {
    return this.client.rootOptions(params, body, this.withAccessToken(additionalParams))
  }

  get (path, params, body, additionalParams) {
    return this.client.get(path, params, body, this.withAccessToken(additionalParams))
  }

  post (path, params, body, additionalParams) {
    return this.client.post(path, params, body, this.withAccessToken(additionalParams))
  }

  put (path, params, body, additionalParams) {
    return this.client.put(path, params, body, this.withAccessToken(additionalParams))
  }

  delete (path, params, body, additionalParams) {
    return this.client.delete(path, params, body, this.withAccessToken(additionalParams))
  }
}

export function initApiGatewayClient ({ accessKeyId, secretAccessKey, sessionToken } = {}, accessToken) {
  cachedClient = new DecoratedApiGatewayClient(window.apigClientFactory.newClient({
    accessKey: accessKeyId,
    secretKey: secretAccessKey,
    sessionToken: sessionToken,
    region: awsRegion
  }), accessToken)

  if (accessKeyId && secretAccessKey && sessionToken) {
    cachedClientWithCredentials = cachedClient
  }

  window.apigw = cachedClient
}

export function apiGatewayClient () {
  if (cachedClient) return Promise.resolve(cachedClient)
  return new Promise(resolve => {
    const poller = setInterval(() => {
      if (cachedClient) {
        clearInterval(poller)
        resolve(cachedClient)
      }
    }, 100)
  })
}

export function apiGatewayClientWithCredentials () {
  if (cachedClientWithCredentials) { return Promise.resolve(cachedClientWithCredentials) }
  return new Promise(resolve => {
    const poller = setInterval(() => {
      if (cachedClientWithCredentials) {
        clearInterval(poller)
        resolve(cachedClientWithCredentials)
      }
    }, 100)
  })
}

export function getCognitoUrl (type) {
  const redirectUri = getLoginRedirectUrl()
  return `${cognitoDomain}/${type}?response_type=code&identity_provider=${identityProvider}&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
}
