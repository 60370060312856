// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'

// content-fragments
import { loadFragments } from 'services/get-fragments'

// services
import { store } from 'services/state'
import { updateAllUserData } from 'services/api-catalog'
import {
  initApiGatewayClient,
  apiGatewayClient,
  cognitoDomain,
  cognitoIdentityPoolId,
  cognitoUserPoolId,
  cognitoClientId,
  cognitoRegion,
  cognitoRefreshToken, cognitoAccessToken
} from 'services/api'
import * as jwtDecode from 'jwt-decode'

export function isAuthenticated () {
  return store.idToken
}

function getPreferredRole () {
  return jwtDecode(store.idToken)['cognito:preferred_role'] || ''
}

export function isRegistered () {
  if (!store.idToken) {
    return false
  }

  const role = getPreferredRole()
  return (
    // FIXME: Hotfix for perftests environment. Role name is too long and gets truncated, removing the last `e` letter.
    // https://tfs.sclab.intel.com:8080/tfs/ISD_Collection/Hosting/_workitems/edit/220697
    role.includes('-ApidpCognitoRegisteredRol-') ||
    role.includes('-ApidpCognitoRegisteredRole-')
  )
}

let logoutTimer

function getRemainingSessionTime (idToken) {
  return jwtDecode(idToken).exp * 1000 - Date.now()
}

/**
 * On page load, look for an active cookie. If it exists and isn't expired, great, use it. Otherwise, clear everything and make sure we're not logged in.
 */
export function init () {
  // attempt to refresh credentials from active session

  let idToken
  let diff = 0

  try {
    idToken = window.localStorage.getItem(cognitoUserPoolId)
    if (idToken) diff = getRemainingSessionTime(idToken)
  } catch (error) {
    console.error(error)
  }

  if (diff > 0) {
    store.idToken = idToken
    store.accessToken = window.localStorage.getItem(cognitoAccessToken)
    store.refreshToken = window.localStorage.getItem(cognitoRefreshToken)
    logoutTimer = setTimeout(logout, diff)
    setCredentials()
  } else {
    initApiGatewayClient() // init a blank client (will get overwritten if we have creds)
    logout()
  }
  loadFragments()
}

/**
 * Gets triggered by the callback from the cognito user pool. Pretty much all it does is grab and store the idToken.
 */
export function login () {
  return new Promise((resolve, reject) => {
    let code
    // let accessToken, username

    try {
      window.location.search
        .split('&')
        .map(param => param.split('='))
        .forEach(param => {
          // record the id_token and access_token
          if (param[0] === 'code') code = param[1]
        })

      if (code) {
        fetch(`${cognitoDomain}/oauth2/token`, {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          },
          body: `grant_type=authorization_code&client_id=${cognitoClientId}&code=${code}&redirect_uri=${getLoginRedirectUrl()}`.trim()
        }).then(async response => {
            const responseBody = await response.json()
            window.localStorage.setItem(cognitoUserPoolId, responseBody.id_token)
            window.localStorage.setItem(cognitoRefreshToken, responseBody.refresh_token)
            window.localStorage.setItem(cognitoAccessToken, responseBody.access_token)

            store.idToken = responseBody.id_token
            store.refreshToken = responseBody.refresh_token
            store.accessToken = responseBody.access_token

            logoutTimer = setTimeout(logout, getRemainingSessionTime(responseBody.id_token))

            setCredentials()

            resolve(responseBody.id_token)
        })
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getLoginRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=login`
export const getLogoutRedirectUrl = () =>
  `${window.location.protocol}//${window.location.host}/index.html?action=logout`

function setCredentials () {
  const preferredRole = jwtDecode(store.idToken)['cognito:preferred_role']
  const params = {
    IdentityPoolId: cognitoIdentityPoolId,
    Logins: {
      [`cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`]: store.idToken
    }
  }

  if (preferredRole) params.RoleArn = preferredRole

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params)

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh(error => {
      if (error) {
        console.error(error)
        return reject(error)
      }

      initApiGatewayClient(AWS.config.credentials, store.accessToken)
      store.user = { email: jwtDecode(store.idToken).email }
      updateAllUserData()

      return apiGatewayClient()
        .then(apiGatewayClient => apiGatewayClient.post('/signin', {}, {}, {}))
    })
  })
}

/**
 * Callback for the Cognito User Pool's logout just to make sure we clean up everything.
 */
export function logout () {
  clearTimeout(logoutTimer)
  logoutTimer = undefined
  if (store.refreshToken) {
    const revokeContent = {
      client_id: cognitoClientId,
      token: store.refreshToken
    }
    let formBody = []
    for (const property in revokeContent) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(revokeContent[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }
    formBody = formBody.join('&')
    fetch(`${cognitoDomain}/oauth2/revoke`, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: formBody
    })
    store.resetUserData()
    initApiGatewayClient()
    window.localStorage.clear()
    window.sessionStorage.clear()

    if (cognitoDomain) {
      // redirect to cognito to log out there, too
      const redirectUrl = getLogoutRedirectUrl()
      window.location = `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${redirectUrl}&scope=cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`
    }
  }
}
