import React from 'react'

const Banner = props => {
  return (
    <div>
      <h2 className="banner-title">INTEL<sup>&#174;</sup> ON DEMAND{window.config.bannerAppendix}</h2>
    </div>
  )
}

export default Banner
