import React from 'react'

// semantic-ui
import { Container } from 'semantic-ui-react'

const PageContentWrapper = ({ children }) => {
  return (
    <Container className="page-content-wrapper">
      {children}
    </Container>
  )
}

export default PageContentWrapper
